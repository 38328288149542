import React from "react";

type Props = {
  toggleText: string;
  dropdownElements: React.ReactNode[];
};
export default function DropdownMenu({ toggleText, dropdownElements }: Props) {
  return (
    <div className="dropdown-menu">
      <label className="dropdown-menu-toggle">
        {toggleText}
        <span className="triangle"></span>
      </label>
      <ul>
        {dropdownElements.map((element, index) => (
          <li key={index}>{element}</li>
        ))}
      </ul>
    </div>
  );
}
