import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type Access = {
  __typename?: 'Access';
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  startDate: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type AccessCountAggregate = {
  __typename?: 'AccessCountAggregate';
  _all: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isAdmin: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type AccessCountOrderByAggregateInput = {
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AccessCreateInput = {
  endDate: Scalars['DateTimeISO']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  user: UserCreateNestedOneWithoutAccessRightsInput;
};

export type AccessCreateManyInput = {
  endDate: Scalars['DateTimeISO']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userId: Scalars['String']['input'];
};

export type AccessCreateManyUserInput = {
  endDate: Scalars['DateTimeISO']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AccessCreateManyUserInputEnvelope = {
  data: Array<AccessCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AccessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccessCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AccessCreateWithoutUserInput>>;
  createMany?: InputMaybe<AccessCreateManyUserInputEnvelope>;
};

export type AccessCreateOrConnectWithoutUserInput = {
  create: AccessCreateWithoutUserInput;
  where: AccessWhereUniqueInput;
};

export type AccessCreateWithoutUserInput = {
  endDate: Scalars['DateTimeISO']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AccessGroupBy = {
  __typename?: 'AccessGroupBy';
  _count?: Maybe<AccessCountAggregate>;
  _max?: Maybe<AccessMaxAggregate>;
  _min?: Maybe<AccessMinAggregate>;
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  startDate: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type AccessListRelationFilter = {
  every?: InputMaybe<AccessWhereInput>;
  none?: InputMaybe<AccessWhereInput>;
  some?: InputMaybe<AccessWhereInput>;
};

export type AccessMaxAggregate = {
  __typename?: 'AccessMaxAggregate';
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AccessMaxOrderByAggregateInput = {
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AccessMinAggregate = {
  __typename?: 'AccessMinAggregate';
  endDate?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AccessMinOrderByAggregateInput = {
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AccessOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AccessOrderByWithAggregationInput = {
  _count?: InputMaybe<AccessCountOrderByAggregateInput>;
  _max?: InputMaybe<AccessMaxOrderByAggregateInput>;
  _min?: InputMaybe<AccessMinOrderByAggregateInput>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AccessOrderByWithRelationInput = {
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdmin?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum AccessScalarFieldEnum {
  EndDate = 'endDate',
  Id = 'id',
  IsAdmin = 'isAdmin',
  StartDate = 'startDate',
  UserId = 'userId'
}

export type AccessScalarWhereInput = {
  AND?: InputMaybe<Array<AccessScalarWhereInput>>;
  NOT?: InputMaybe<Array<AccessScalarWhereInput>>;
  OR?: InputMaybe<Array<AccessScalarWhereInput>>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdmin?: InputMaybe<BoolFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AccessScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AccessScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AccessScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AccessScalarWhereWithAggregatesInput>>;
  endDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isAdmin?: InputMaybe<BoolWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type AccessUpdateInput = {
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAccessRightsNestedInput>;
};

export type AccessUpdateManyMutationInput = {
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccessUpdateManyWithWhereWithoutUserInput = {
  data: AccessUpdateManyMutationInput;
  where: AccessScalarWhereInput;
};

export type AccessUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<AccessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccessCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AccessCreateWithoutUserInput>>;
  createMany?: InputMaybe<AccessCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AccessWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AccessScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AccessWhereUniqueInput>>;
  set?: InputMaybe<Array<AccessWhereUniqueInput>>;
  update?: InputMaybe<Array<AccessUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<AccessUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<AccessUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AccessUpdateWithWhereUniqueWithoutUserInput = {
  data: AccessUpdateWithoutUserInput;
  where: AccessWhereUniqueInput;
};

export type AccessUpdateWithoutUserInput = {
  endDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccessUpsertWithWhereUniqueWithoutUserInput = {
  create: AccessCreateWithoutUserInput;
  update: AccessUpdateWithoutUserInput;
  where: AccessWhereUniqueInput;
};

export type AccessWhereInput = {
  AND?: InputMaybe<Array<AccessWhereInput>>;
  NOT?: InputMaybe<Array<AccessWhereInput>>;
  OR?: InputMaybe<Array<AccessWhereInput>>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdmin?: InputMaybe<BoolFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AccessWhereUniqueInput = {
  AND?: InputMaybe<Array<AccessWhereInput>>;
  NOT?: InputMaybe<Array<AccessWhereInput>>;
  OR?: InputMaybe<Array<AccessWhereInput>>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<BoolFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type AggregateAccess = {
  __typename?: 'AggregateAccess';
  _count?: Maybe<AccessCountAggregate>;
  _max?: Maybe<AccessMaxAggregate>;
  _min?: Maybe<AccessMinAggregate>;
};

export type AggregateLecture = {
  __typename?: 'AggregateLecture';
  _count?: Maybe<LectureCountAggregate>;
  _max?: Maybe<LectureMaxAggregate>;
  _min?: Maybe<LectureMinAggregate>;
};

export type AggregateMetadata = {
  __typename?: 'AggregateMetadata';
  _count?: Maybe<MetadataCountAggregate>;
  _max?: Maybe<MetadataMaxAggregate>;
  _min?: Maybe<MetadataMinAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type EnumLanguageFieldUpdateOperationsInput = {
  set?: InputMaybe<Language>;
};

export type EnumLanguageFilter = {
  equals?: InputMaybe<Language>;
  in?: InputMaybe<Array<Language>>;
  not?: InputMaybe<NestedEnumLanguageFilter>;
  notIn?: InputMaybe<Array<Language>>;
};

export type EnumLanguageWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLanguageFilter>;
  _min?: InputMaybe<NestedEnumLanguageFilter>;
  equals?: InputMaybe<Language>;
  in?: InputMaybe<Array<Language>>;
  not?: InputMaybe<NestedEnumLanguageWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Language>>;
};

export enum Language {
  Czech = 'CZECH',
  English = 'ENGLISH',
  Slovak = 'SLOVAK'
}

export type Lecture = {
  __typename?: 'Lecture';
  _count?: Maybe<LectureCount>;
  addedAt: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  language: Language;
  linkToVideoFile: Scalars['String']['output'];
  linkToVideoThumbnail: Scalars['String']['output'];
  metadata: Array<Metadata>;
  published: Scalars['Boolean']['output'];
  room?: Maybe<Scalars['String']['output']>;
  speakers?: Maybe<Scalars['String']['output']>;
  speakersBio?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


export type LectureMetadataArgs = {
  cursor?: InputMaybe<MetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetadataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWhereInput>;
};

export type LectureCount = {
  __typename?: 'LectureCount';
  metadata: Scalars['Int']['output'];
};


export type LectureCountMetadataArgs = {
  where?: InputMaybe<MetadataWhereInput>;
};

export type LectureCountAggregate = {
  __typename?: 'LectureCountAggregate';
  _all: Scalars['Int']['output'];
  addedAt: Scalars['Int']['output'];
  date: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  language: Scalars['Int']['output'];
  linkToVideoFile: Scalars['Int']['output'];
  linkToVideoThumbnail: Scalars['Int']['output'];
  published: Scalars['Int']['output'];
  room: Scalars['Int']['output'];
  speakers: Scalars['Int']['output'];
  speakersBio: Scalars['Int']['output'];
  summary: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
};

export type LectureCountOrderByAggregateInput = {
  addedAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  linkToVideoFile?: InputMaybe<SortOrder>;
  linkToVideoThumbnail?: InputMaybe<SortOrder>;
  published?: InputMaybe<SortOrder>;
  room?: InputMaybe<SortOrder>;
  speakers?: InputMaybe<SortOrder>;
  speakersBio?: InputMaybe<SortOrder>;
  summary?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type LectureCreateInput = {
  addedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  date: Scalars['DateTimeISO']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  language: Language;
  linkToVideoFile: Scalars['String']['input'];
  linkToVideoThumbnail: Scalars['String']['input'];
  metadata?: InputMaybe<MetadataCreateNestedManyWithoutLectureInput>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
  speakers?: InputMaybe<Scalars['String']['input']>;
  speakersBio?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type LectureCreateManyInput = {
  addedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  date: Scalars['DateTimeISO']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  language: Language;
  linkToVideoFile: Scalars['String']['input'];
  linkToVideoThumbnail: Scalars['String']['input'];
  published?: InputMaybe<Scalars['Boolean']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
  speakers?: InputMaybe<Scalars['String']['input']>;
  speakersBio?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type LectureCreateNestedOneWithoutMetadataInput = {
  connect?: InputMaybe<LectureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LectureCreateOrConnectWithoutMetadataInput>;
  create?: InputMaybe<LectureCreateWithoutMetadataInput>;
};

export type LectureCreateOrConnectWithoutMetadataInput = {
  create: LectureCreateWithoutMetadataInput;
  where: LectureWhereUniqueInput;
};

export type LectureCreateWithoutMetadataInput = {
  addedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  date: Scalars['DateTimeISO']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  language: Language;
  linkToVideoFile: Scalars['String']['input'];
  linkToVideoThumbnail: Scalars['String']['input'];
  published?: InputMaybe<Scalars['Boolean']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
  speakers?: InputMaybe<Scalars['String']['input']>;
  speakersBio?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type LectureGroupBy = {
  __typename?: 'LectureGroupBy';
  _count?: Maybe<LectureCountAggregate>;
  _max?: Maybe<LectureMaxAggregate>;
  _min?: Maybe<LectureMinAggregate>;
  addedAt: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  language: Language;
  linkToVideoFile: Scalars['String']['output'];
  linkToVideoThumbnail: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  room?: Maybe<Scalars['String']['output']>;
  speakers?: Maybe<Scalars['String']['output']>;
  speakersBio?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type LectureMaxAggregate = {
  __typename?: 'LectureMaxAggregate';
  addedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  date?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Language>;
  linkToVideoFile?: Maybe<Scalars['String']['output']>;
  linkToVideoThumbnail?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  room?: Maybe<Scalars['String']['output']>;
  speakers?: Maybe<Scalars['String']['output']>;
  speakersBio?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LectureMaxOrderByAggregateInput = {
  addedAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  linkToVideoFile?: InputMaybe<SortOrder>;
  linkToVideoThumbnail?: InputMaybe<SortOrder>;
  published?: InputMaybe<SortOrder>;
  room?: InputMaybe<SortOrder>;
  speakers?: InputMaybe<SortOrder>;
  speakersBio?: InputMaybe<SortOrder>;
  summary?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type LectureMinAggregate = {
  __typename?: 'LectureMinAggregate';
  addedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  date?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Language>;
  linkToVideoFile?: Maybe<Scalars['String']['output']>;
  linkToVideoThumbnail?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  room?: Maybe<Scalars['String']['output']>;
  speakers?: Maybe<Scalars['String']['output']>;
  speakersBio?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LectureMinOrderByAggregateInput = {
  addedAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  linkToVideoFile?: InputMaybe<SortOrder>;
  linkToVideoThumbnail?: InputMaybe<SortOrder>;
  published?: InputMaybe<SortOrder>;
  room?: InputMaybe<SortOrder>;
  speakers?: InputMaybe<SortOrder>;
  speakersBio?: InputMaybe<SortOrder>;
  summary?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type LectureOrderByWithAggregationInput = {
  _count?: InputMaybe<LectureCountOrderByAggregateInput>;
  _max?: InputMaybe<LectureMaxOrderByAggregateInput>;
  _min?: InputMaybe<LectureMinOrderByAggregateInput>;
  addedAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  linkToVideoFile?: InputMaybe<SortOrder>;
  linkToVideoThumbnail?: InputMaybe<SortOrder>;
  published?: InputMaybe<SortOrder>;
  room?: InputMaybe<SortOrderInput>;
  speakers?: InputMaybe<SortOrderInput>;
  speakersBio?: InputMaybe<SortOrderInput>;
  summary?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
};

export type LectureOrderByWithRelationInput = {
  addedAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  linkToVideoFile?: InputMaybe<SortOrder>;
  linkToVideoThumbnail?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<MetadataOrderByRelationAggregateInput>;
  published?: InputMaybe<SortOrder>;
  room?: InputMaybe<SortOrderInput>;
  speakers?: InputMaybe<SortOrderInput>;
  speakersBio?: InputMaybe<SortOrderInput>;
  summary?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
};

export type LectureRelationFilter = {
  is?: InputMaybe<LectureWhereInput>;
  isNot?: InputMaybe<LectureWhereInput>;
};

export enum LectureScalarFieldEnum {
  AddedAt = 'addedAt',
  Date = 'date',
  Id = 'id',
  Language = 'language',
  LinkToVideoFile = 'linkToVideoFile',
  LinkToVideoThumbnail = 'linkToVideoThumbnail',
  Published = 'published',
  Room = 'room',
  Speakers = 'speakers',
  SpeakersBio = 'speakersBio',
  Summary = 'summary',
  Title = 'title'
}

export type LectureScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LectureScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LectureScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LectureScalarWhereWithAggregatesInput>>;
  addedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  date?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  language?: InputMaybe<EnumLanguageWithAggregatesFilter>;
  linkToVideoFile?: InputMaybe<StringWithAggregatesFilter>;
  linkToVideoThumbnail?: InputMaybe<StringWithAggregatesFilter>;
  published?: InputMaybe<BoolWithAggregatesFilter>;
  room?: InputMaybe<StringNullableWithAggregatesFilter>;
  speakers?: InputMaybe<StringNullableWithAggregatesFilter>;
  speakersBio?: InputMaybe<StringNullableWithAggregatesFilter>;
  summary?: InputMaybe<StringNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
};

export type LectureUpdateInput = {
  addedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageFieldUpdateOperationsInput>;
  linkToVideoFile?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkToVideoThumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<MetadataUpdateManyWithoutLectureNestedInput>;
  published?: InputMaybe<BoolFieldUpdateOperationsInput>;
  room?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  speakers?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  speakersBio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  summary?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LectureUpdateManyMutationInput = {
  addedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageFieldUpdateOperationsInput>;
  linkToVideoFile?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkToVideoThumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  published?: InputMaybe<BoolFieldUpdateOperationsInput>;
  room?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  speakers?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  speakersBio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  summary?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LectureUpdateOneRequiredWithoutMetadataNestedInput = {
  connect?: InputMaybe<LectureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LectureCreateOrConnectWithoutMetadataInput>;
  create?: InputMaybe<LectureCreateWithoutMetadataInput>;
  update?: InputMaybe<LectureUpdateToOneWithWhereWithoutMetadataInput>;
  upsert?: InputMaybe<LectureUpsertWithoutMetadataInput>;
};

export type LectureUpdateToOneWithWhereWithoutMetadataInput = {
  data: LectureUpdateWithoutMetadataInput;
  where?: InputMaybe<LectureWhereInput>;
};

export type LectureUpdateWithoutMetadataInput = {
  addedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageFieldUpdateOperationsInput>;
  linkToVideoFile?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkToVideoThumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  published?: InputMaybe<BoolFieldUpdateOperationsInput>;
  room?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  speakers?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  speakersBio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  summary?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LectureUpsertWithoutMetadataInput = {
  create: LectureCreateWithoutMetadataInput;
  update: LectureUpdateWithoutMetadataInput;
  where?: InputMaybe<LectureWhereInput>;
};

export type LectureWhereInput = {
  AND?: InputMaybe<Array<LectureWhereInput>>;
  NOT?: InputMaybe<Array<LectureWhereInput>>;
  OR?: InputMaybe<Array<LectureWhereInput>>;
  addedAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  language?: InputMaybe<EnumLanguageFilter>;
  linkToVideoFile?: InputMaybe<StringFilter>;
  linkToVideoThumbnail?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<MetadataListRelationFilter>;
  published?: InputMaybe<BoolFilter>;
  room?: InputMaybe<StringNullableFilter>;
  speakers?: InputMaybe<StringNullableFilter>;
  speakersBio?: InputMaybe<StringNullableFilter>;
  summary?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

export type LectureWhereUniqueInput = {
  AND?: InputMaybe<Array<LectureWhereInput>>;
  NOT?: InputMaybe<Array<LectureWhereInput>>;
  OR?: InputMaybe<Array<LectureWhereInput>>;
  addedAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<EnumLanguageFilter>;
  linkToVideoFile?: InputMaybe<StringFilter>;
  linkToVideoThumbnail?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<MetadataListRelationFilter>;
  published?: InputMaybe<BoolFilter>;
  room?: InputMaybe<StringNullableFilter>;
  speakers?: InputMaybe<StringNullableFilter>;
  speakersBio?: InputMaybe<StringNullableFilter>;
  summary?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

export type Metadata = {
  __typename?: 'Metadata';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  lectureId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetadataCountAggregate = {
  __typename?: 'MetadataCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  lectureId: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type MetadataCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  lectureId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type MetadataCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  lecture: LectureCreateNestedOneWithoutMetadataInput;
  value: Scalars['String']['input'];
};

export type MetadataCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  lectureId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MetadataCreateManyLectureInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MetadataCreateManyLectureInputEnvelope = {
  data: Array<MetadataCreateManyLectureInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetadataCreateNestedManyWithoutLectureInput = {
  connect?: InputMaybe<Array<MetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MetadataCreateOrConnectWithoutLectureInput>>;
  create?: InputMaybe<Array<MetadataCreateWithoutLectureInput>>;
  createMany?: InputMaybe<MetadataCreateManyLectureInputEnvelope>;
};

export type MetadataCreateOrConnectWithoutLectureInput = {
  create: MetadataCreateWithoutLectureInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreateWithoutLectureInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MetadataGroupBy = {
  __typename?: 'MetadataGroupBy';
  _count?: Maybe<MetadataCountAggregate>;
  _max?: Maybe<MetadataMaxAggregate>;
  _min?: Maybe<MetadataMinAggregate>;
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  lectureId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetadataListRelationFilter = {
  every?: InputMaybe<MetadataWhereInput>;
  none?: InputMaybe<MetadataWhereInput>;
  some?: InputMaybe<MetadataWhereInput>;
};

export type MetadataMaxAggregate = {
  __typename?: 'MetadataMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  lectureId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  lectureId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type MetadataMinAggregate = {
  __typename?: 'MetadataMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  lectureId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  lectureId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type MetadataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MetadataOrderByWithAggregationInput = {
  _count?: InputMaybe<MetadataCountOrderByAggregateInput>;
  _max?: InputMaybe<MetadataMaxOrderByAggregateInput>;
  _min?: InputMaybe<MetadataMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  lectureId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type MetadataOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  lecture?: InputMaybe<LectureOrderByWithRelationInput>;
  lectureId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum MetadataScalarFieldEnum {
  Id = 'id',
  Key = 'key',
  LectureId = 'lectureId',
  Value = 'value'
}

export type MetadataScalarWhereInput = {
  AND?: InputMaybe<Array<MetadataScalarWhereInput>>;
  NOT?: InputMaybe<Array<MetadataScalarWhereInput>>;
  OR?: InputMaybe<Array<MetadataScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  lectureId?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type MetadataScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MetadataScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MetadataScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MetadataScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  key?: InputMaybe<StringWithAggregatesFilter>;
  lectureId?: InputMaybe<StringWithAggregatesFilter>;
  value?: InputMaybe<StringWithAggregatesFilter>;
};

export type MetadataUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  lecture?: InputMaybe<LectureUpdateOneRequiredWithoutMetadataNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MetadataUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MetadataUpdateManyWithWhereWithoutLectureInput = {
  data: MetadataUpdateManyMutationInput;
  where: MetadataScalarWhereInput;
};

export type MetadataUpdateManyWithoutLectureNestedInput = {
  connect?: InputMaybe<Array<MetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MetadataCreateOrConnectWithoutLectureInput>>;
  create?: InputMaybe<Array<MetadataCreateWithoutLectureInput>>;
  createMany?: InputMaybe<MetadataCreateManyLectureInputEnvelope>;
  delete?: InputMaybe<Array<MetadataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MetadataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MetadataWhereUniqueInput>>;
  set?: InputMaybe<Array<MetadataWhereUniqueInput>>;
  update?: InputMaybe<Array<MetadataUpdateWithWhereUniqueWithoutLectureInput>>;
  updateMany?: InputMaybe<Array<MetadataUpdateManyWithWhereWithoutLectureInput>>;
  upsert?: InputMaybe<Array<MetadataUpsertWithWhereUniqueWithoutLectureInput>>;
};

export type MetadataUpdateWithWhereUniqueWithoutLectureInput = {
  data: MetadataUpdateWithoutLectureInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUpdateWithoutLectureInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MetadataUpsertWithWhereUniqueWithoutLectureInput = {
  create: MetadataCreateWithoutLectureInput;
  update: MetadataUpdateWithoutLectureInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWhereInput = {
  AND?: InputMaybe<Array<MetadataWhereInput>>;
  NOT?: InputMaybe<Array<MetadataWhereInput>>;
  OR?: InputMaybe<Array<MetadataWhereInput>>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  lecture?: InputMaybe<LectureRelationFilter>;
  lectureId?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type MetadataWhereUniqueInput = {
  AND?: InputMaybe<Array<MetadataWhereInput>>;
  NOT?: InputMaybe<Array<MetadataWhereInput>>;
  OR?: InputMaybe<Array<MetadataWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<StringFilter>;
  lecture?: InputMaybe<LectureRelationFilter>;
  lectureId?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createManyAccess: AffectedRowsOutput;
  createManyLecture: AffectedRowsOutput;
  createManyMetadata: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createOneAccess: Access;
  createOneLecture: Lecture;
  createOneMetadata: Metadata;
  createOneUser: User;
  deleteManyAccess: AffectedRowsOutput;
  deleteManyLecture: AffectedRowsOutput;
  deleteManyMetadata: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteOneAccess?: Maybe<Access>;
  deleteOneLecture?: Maybe<Lecture>;
  deleteOneMetadata?: Maybe<Metadata>;
  deleteOneUser?: Maybe<User>;
  sendRegistrationLink: Scalars['String']['output'];
  updateManyAccess: AffectedRowsOutput;
  updateManyLecture: AffectedRowsOutput;
  updateManyMetadata: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateOneAccess?: Maybe<Access>;
  updateOneLecture?: Maybe<Lecture>;
  updateOneMetadata?: Maybe<Metadata>;
  updateOneUser?: Maybe<User>;
  upsertOneAccess: Access;
  upsertOneLecture: Lecture;
  upsertOneMetadata: Metadata;
  upsertOneUser: User;
};


export type MutationCreateManyAccessArgs = {
  data: Array<AccessCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyLectureArgs = {
  data: Array<LectureCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyMetadataArgs = {
  data: Array<MetadataCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateOneAccessArgs = {
  data: AccessCreateInput;
};


export type MutationCreateOneLectureArgs = {
  data: LectureCreateInput;
};


export type MutationCreateOneMetadataArgs = {
  data: MetadataCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteManyAccessArgs = {
  where?: InputMaybe<AccessWhereInput>;
};


export type MutationDeleteManyLectureArgs = {
  where?: InputMaybe<LectureWhereInput>;
};


export type MutationDeleteManyMetadataArgs = {
  where?: InputMaybe<MetadataWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteOneAccessArgs = {
  where: AccessWhereUniqueInput;
};


export type MutationDeleteOneLectureArgs = {
  where: LectureWhereUniqueInput;
};


export type MutationDeleteOneMetadataArgs = {
  where: MetadataWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationSendRegistrationLinkArgs = {
  accessUntil: Scalars['DateTimeISO']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUpdateManyAccessArgs = {
  data: AccessUpdateManyMutationInput;
  where?: InputMaybe<AccessWhereInput>;
};


export type MutationUpdateManyLectureArgs = {
  data: LectureUpdateManyMutationInput;
  where?: InputMaybe<LectureWhereInput>;
};


export type MutationUpdateManyMetadataArgs = {
  data: MetadataUpdateManyMutationInput;
  where?: InputMaybe<MetadataWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateOneAccessArgs = {
  data: AccessUpdateInput;
  where: AccessWhereUniqueInput;
};


export type MutationUpdateOneLectureArgs = {
  data: LectureUpdateInput;
  where: LectureWhereUniqueInput;
};


export type MutationUpdateOneMetadataArgs = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneAccessArgs = {
  create: AccessCreateInput;
  update: AccessUpdateInput;
  where: AccessWhereUniqueInput;
};


export type MutationUpsertOneLectureArgs = {
  create: LectureCreateInput;
  update: LectureUpdateInput;
  where: LectureWhereUniqueInput;
};


export type MutationUpsertOneMetadataArgs = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};


export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedEnumLanguageFilter = {
  equals?: InputMaybe<Language>;
  in?: InputMaybe<Array<Language>>;
  not?: InputMaybe<NestedEnumLanguageFilter>;
  notIn?: InputMaybe<Array<Language>>;
};

export type NestedEnumLanguageWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLanguageFilter>;
  _min?: InputMaybe<NestedEnumLanguageFilter>;
  equals?: InputMaybe<Language>;
  in?: InputMaybe<Array<Language>>;
  not?: InputMaybe<NestedEnumLanguageWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Language>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Query = {
  __typename?: 'Query';
  access?: Maybe<Access>;
  accesses: Array<Access>;
  aggregateAccess: AggregateAccess;
  aggregateLecture: AggregateLecture;
  aggregateMetadata: AggregateMetadata;
  aggregateUser: AggregateUser;
  authenticateServiceAccount: Scalars['String']['output'];
  fetchVimeoToken: Scalars['String']['output'];
  findFirstAccess?: Maybe<Access>;
  findFirstAccessOrThrow?: Maybe<Access>;
  findFirstLecture?: Maybe<Lecture>;
  findFirstLectureOrThrow?: Maybe<Lecture>;
  findFirstMetadata?: Maybe<Metadata>;
  findFirstMetadataOrThrow?: Maybe<Metadata>;
  findFirstUser?: Maybe<User>;
  findFirstUserOrThrow?: Maybe<User>;
  findManyMetadata: Array<Metadata>;
  findUniqueMetadata?: Maybe<Metadata>;
  findUniqueMetadataOrThrow?: Maybe<Metadata>;
  getAccess?: Maybe<Access>;
  getLecture?: Maybe<Lecture>;
  getUser?: Maybe<User>;
  getVimeoAuthorizationUrl: Scalars['String']['output'];
  groupByAccess: Array<AccessGroupBy>;
  groupByLecture: Array<LectureGroupBy>;
  groupByMetadata: Array<MetadataGroupBy>;
  groupByUser: Array<UserGroupBy>;
  lecture?: Maybe<Lecture>;
  lectures: Array<Lecture>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryAccessArgs = {
  where: AccessWhereUniqueInput;
};


export type QueryAccessesArgs = {
  cursor?: InputMaybe<AccessWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccessScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccessOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessWhereInput>;
};


export type QueryAggregateAccessArgs = {
  cursor?: InputMaybe<AccessWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AccessOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessWhereInput>;
};


export type QueryAggregateLectureArgs = {
  cursor?: InputMaybe<LectureWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LectureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LectureWhereInput>;
};


export type QueryAggregateMetadataArgs = {
  cursor?: InputMaybe<MetadataWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MetadataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAuthenticateServiceAccountArgs = {
  serviceAccountKey: Scalars['String']['input'];
  serviceAccountKeyId: Scalars['String']['input'];
};


export type QueryFetchVimeoTokenArgs = {
  code: Scalars['String']['input'];
};


export type QueryFindFirstAccessArgs = {
  cursor?: InputMaybe<AccessWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccessScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccessOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessWhereInput>;
};


export type QueryFindFirstAccessOrThrowArgs = {
  cursor?: InputMaybe<AccessWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccessScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccessOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessWhereInput>;
};


export type QueryFindFirstLectureArgs = {
  cursor?: InputMaybe<LectureWhereUniqueInput>;
  distinct?: InputMaybe<Array<LectureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LectureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LectureWhereInput>;
};


export type QueryFindFirstLectureOrThrowArgs = {
  cursor?: InputMaybe<LectureWhereUniqueInput>;
  distinct?: InputMaybe<Array<LectureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LectureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LectureWhereInput>;
};


export type QueryFindFirstMetadataArgs = {
  cursor?: InputMaybe<MetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetadataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWhereInput>;
};


export type QueryFindFirstMetadataOrThrowArgs = {
  cursor?: InputMaybe<MetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetadataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstUserOrThrowArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindManyMetadataArgs = {
  cursor?: InputMaybe<MetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetadataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWhereInput>;
};


export type QueryFindUniqueMetadataArgs = {
  where: MetadataWhereUniqueInput;
};


export type QueryFindUniqueMetadataOrThrowArgs = {
  where: MetadataWhereUniqueInput;
};


export type QueryGetAccessArgs = {
  where: AccessWhereUniqueInput;
};


export type QueryGetLectureArgs = {
  where: LectureWhereUniqueInput;
};


export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetVimeoAuthorizationUrlArgs = {
  state: Scalars['String']['input'];
};


export type QueryGroupByAccessArgs = {
  by: Array<AccessScalarFieldEnum>;
  having?: InputMaybe<AccessScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AccessOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessWhereInput>;
};


export type QueryGroupByLectureArgs = {
  by: Array<LectureScalarFieldEnum>;
  having?: InputMaybe<LectureScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LectureOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LectureWhereInput>;
};


export type QueryGroupByMetadataArgs = {
  by: Array<MetadataScalarFieldEnum>;
  having?: InputMaybe<MetadataScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MetadataOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryLectureArgs = {
  where: LectureWhereUniqueInput;
};


export type QueryLecturesArgs = {
  cursor?: InputMaybe<LectureWhereUniqueInput>;
  distinct?: InputMaybe<Array<LectureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LectureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LectureWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  accessRights: Array<Access>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};


export type UserAccessRightsArgs = {
  cursor?: InputMaybe<AccessWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccessScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccessOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  accessRights: Scalars['Int']['output'];
};


export type UserCountAccessRightsArgs = {
  where?: InputMaybe<AccessWhereInput>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  firstName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
};

export type UserCountOrderByAggregateInput = {
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  accessRights?: InputMaybe<AccessCreateNestedManyWithoutUserInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
};

export type UserCreateManyInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
};

export type UserCreateNestedOneWithoutAccessRightsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAccessRightsInput>;
  create?: InputMaybe<UserCreateWithoutAccessRightsInput>;
};

export type UserCreateOrConnectWithoutAccessRightsInput = {
  create: UserCreateWithoutAccessRightsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAccessRightsInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserMaxOrderByAggregateInput = {
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserMinOrderByAggregateInput = {
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  accessRights?: InputMaybe<AccessOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  firstName?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastName?: InputMaybe<StringWithAggregatesFilter>;
};

export type UserUpdateInput = {
  accessRights?: InputMaybe<AccessUpdateManyWithoutUserNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutAccessRightsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAccessRightsInput>;
  create?: InputMaybe<UserCreateWithoutAccessRightsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAccessRightsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAccessRightsInput>;
};

export type UserUpdateToOneWithWhereWithoutAccessRightsInput = {
  data: UserUpdateWithoutAccessRightsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithoutAccessRightsInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutAccessRightsInput = {
  create: UserCreateWithoutAccessRightsInput;
  update: UserUpdateWithoutAccessRightsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  accessRights?: InputMaybe<AccessListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  accessRights?: InputMaybe<AccessListRelationFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<StringFilter>;
};

export type FetchLecturesQueryVariables = Exact<{
  dateFilter?: InputMaybe<DateTimeFilter>;
  metadataFilter?: InputMaybe<MetadataListRelationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type FetchLecturesQuery = { __typename?: 'Query', lectures: Array<{ __typename?: 'Lecture', id: string, title: string, summary?: string | null, speakers?: string | null, speakersBio?: string | null, language: Language, room?: string | null, date: any, addedAt: any, published: boolean, linkToVideoFile: string, linkToVideoThumbnail: string, metadata: Array<{ __typename?: 'Metadata', id: string, key: string, value: string, lectureId: string }> }> };

export type FetchLectureQueryVariables = Exact<{
  where: LectureWhereUniqueInput;
}>;


export type FetchLectureQuery = { __typename?: 'Query', lecture?: { __typename?: 'Lecture', id: string, title: string, summary?: string | null, speakers?: string | null, speakersBio?: string | null, language: Language, room?: string | null, date: any, addedAt: any, published: boolean, linkToVideoFile: string, linkToVideoThumbnail: string, metadata: Array<{ __typename?: 'Metadata', id: string, key: string, value: string, lectureId: string }> } | null };

export type CreateLectureMutationVariables = Exact<{
  data: LectureCreateInput;
}>;


export type CreateLectureMutation = { __typename?: 'Mutation', createOneLecture: { __typename?: 'Lecture', id: string, title: string, summary?: string | null, speakers?: string | null, speakersBio?: string | null, language: Language, room?: string | null, date: any, addedAt: any, published: boolean, linkToVideoFile: string, linkToVideoThumbnail: string } };

export type UpdateLectureMutationVariables = Exact<{
  data: LectureUpdateInput;
  where: LectureWhereUniqueInput;
}>;


export type UpdateLectureMutation = { __typename?: 'Mutation', updateOneLecture?: { __typename?: 'Lecture', id: string, title: string, summary?: string | null, speakers?: string | null, speakersBio?: string | null, language: Language, room?: string | null, date: any, addedAt: any, published: boolean, linkToVideoFile: string, linkToVideoThumbnail: string } | null };

export type FetchUserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type FetchUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, accessRights: Array<{ __typename?: 'Access', id: string, startDate: any, endDate: any, isAdmin: boolean, userId: string }> } | null };

export type FetchVimeoTokenQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type FetchVimeoTokenQuery = { __typename?: 'Query', fetchVimeoToken: string };

export type GetVimeoAuthorizationUrlQueryVariables = Exact<{
  state: Scalars['String']['input'];
}>;


export type GetVimeoAuthorizationUrlQuery = { __typename?: 'Query', getVimeoAuthorizationUrl: string };

import { IntrospectionQuery } from 'graphql';
export default {
  "__schema": {
    "queryType": {
      "name": "Query"
    },
    "mutationType": {
      "name": "Mutation"
    },
    "subscriptionType": null,
    "types": [
      {
        "kind": "OBJECT",
        "name": "Access",
        "fields": [
          {
            "name": "endDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "isAdmin",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AccessCountAggregate",
        "fields": [
          {
            "name": "_all",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "isAdmin",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AccessGroupBy",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "AccessCountAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_max",
            "type": {
              "kind": "OBJECT",
              "name": "AccessMaxAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_min",
            "type": {
              "kind": "OBJECT",
              "name": "AccessMinAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "isAdmin",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AccessMaxAggregate",
        "fields": [
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "isAdmin",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AccessMinAggregate",
        "fields": [
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "isAdmin",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AffectedRowsOutput",
        "fields": [
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AggregateAccess",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "AccessCountAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_max",
            "type": {
              "kind": "OBJECT",
              "name": "AccessMaxAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_min",
            "type": {
              "kind": "OBJECT",
              "name": "AccessMinAggregate",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AggregateLecture",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "LectureCountAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_max",
            "type": {
              "kind": "OBJECT",
              "name": "LectureMaxAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_min",
            "type": {
              "kind": "OBJECT",
              "name": "LectureMinAggregate",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AggregateMetadata",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "MetadataCountAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_max",
            "type": {
              "kind": "OBJECT",
              "name": "MetadataMaxAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_min",
            "type": {
              "kind": "OBJECT",
              "name": "MetadataMinAggregate",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "AggregateUser",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "UserCountAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_max",
            "type": {
              "kind": "OBJECT",
              "name": "UserMaxAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_min",
            "type": {
              "kind": "OBJECT",
              "name": "UserMinAggregate",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Lecture",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "LectureCount",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "addedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "language",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "linkToVideoFile",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "linkToVideoThumbnail",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "metadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Metadata",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "published",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "room",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "speakers",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "speakersBio",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "summary",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "LectureCount",
        "fields": [
          {
            "name": "metadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "LectureCountAggregate",
        "fields": [
          {
            "name": "_all",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "addedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "language",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "linkToVideoFile",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "linkToVideoThumbnail",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "published",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "room",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "speakers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "speakersBio",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "summary",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "LectureGroupBy",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "LectureCountAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_max",
            "type": {
              "kind": "OBJECT",
              "name": "LectureMaxAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_min",
            "type": {
              "kind": "OBJECT",
              "name": "LectureMinAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "addedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "language",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "linkToVideoFile",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "linkToVideoThumbnail",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "published",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "room",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "speakers",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "speakersBio",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "summary",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "LectureMaxAggregate",
        "fields": [
          {
            "name": "addedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "language",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "linkToVideoFile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "linkToVideoThumbnail",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "published",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "room",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "speakers",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "speakersBio",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "summary",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "LectureMinAggregate",
        "fields": [
          {
            "name": "addedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "language",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "linkToVideoFile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "linkToVideoThumbnail",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "published",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "room",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "speakers",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "speakersBio",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "summary",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Metadata",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "key",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "lectureId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "MetadataCountAggregate",
        "fields": [
          {
            "name": "_all",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "key",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "lectureId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "MetadataGroupBy",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "MetadataCountAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_max",
            "type": {
              "kind": "OBJECT",
              "name": "MetadataMaxAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_min",
            "type": {
              "kind": "OBJECT",
              "name": "MetadataMinAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "key",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "lectureId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "MetadataMaxAggregate",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "key",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "lectureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "MetadataMinAggregate",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "key",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "lectureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Mutation",
        "fields": [
          {
            "name": "createManyAccess",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              },
              {
                "name": "skipDuplicates",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "createManyLecture",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              },
              {
                "name": "skipDuplicates",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "createManyMetadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              },
              {
                "name": "skipDuplicates",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "createManyUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              },
              {
                "name": "skipDuplicates",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "createOneAccess",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Access",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "createOneLecture",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Lecture",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "createOneMetadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Metadata",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "createOneUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "deleteManyAccess",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "deleteManyLecture",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "deleteManyMetadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "deleteManyUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "deleteOneAccess",
            "type": {
              "kind": "OBJECT",
              "name": "Access",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOneLecture",
            "type": {
              "kind": "OBJECT",
              "name": "Lecture",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOneMetadata",
            "type": {
              "kind": "OBJECT",
              "name": "Metadata",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOneUser",
            "type": {
              "kind": "OBJECT",
              "name": "User",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "sendRegistrationLink",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": [
              {
                "name": "accessUntil",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "email",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "firstName",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "lastName",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "updateManyAccess",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "updateManyLecture",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "updateManyMetadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "updateManyUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AffectedRowsOutput",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "updateOneAccess",
            "type": {
              "kind": "OBJECT",
              "name": "Access",
              "ofType": null
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "updateOneLecture",
            "type": {
              "kind": "OBJECT",
              "name": "Lecture",
              "ofType": null
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "updateOneMetadata",
            "type": {
              "kind": "OBJECT",
              "name": "Metadata",
              "ofType": null
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "updateOneUser",
            "type": {
              "kind": "OBJECT",
              "name": "User",
              "ofType": null
            },
            "args": [
              {
                "name": "data",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "upsertOneAccess",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Access",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "create",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "update",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "upsertOneLecture",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Lecture",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "create",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "update",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "upsertOneMetadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Metadata",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "create",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "update",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "upsertOneUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "create",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "update",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Query",
        "fields": [
          {
            "name": "access",
            "type": {
              "kind": "OBJECT",
              "name": "Access",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "accesses",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Access",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "aggregateAccess",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AggregateAccess",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "aggregateLecture",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AggregateLecture",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "aggregateMetadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AggregateMetadata",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "aggregateUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "AggregateUser",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "authenticateServiceAccount",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": [
              {
                "name": "serviceAccountKey",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "serviceAccountKeyId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "fetchVimeoToken",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": [
              {
                "name": "code",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "findFirstAccess",
            "type": {
              "kind": "OBJECT",
              "name": "Access",
              "ofType": null
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findFirstAccessOrThrow",
            "type": {
              "kind": "OBJECT",
              "name": "Access",
              "ofType": null
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findFirstLecture",
            "type": {
              "kind": "OBJECT",
              "name": "Lecture",
              "ofType": null
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findFirstLectureOrThrow",
            "type": {
              "kind": "OBJECT",
              "name": "Lecture",
              "ofType": null
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findFirstMetadata",
            "type": {
              "kind": "OBJECT",
              "name": "Metadata",
              "ofType": null
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findFirstMetadataOrThrow",
            "type": {
              "kind": "OBJECT",
              "name": "Metadata",
              "ofType": null
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findFirstUser",
            "type": {
              "kind": "OBJECT",
              "name": "User",
              "ofType": null
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findFirstUserOrThrow",
            "type": {
              "kind": "OBJECT",
              "name": "User",
              "ofType": null
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findManyMetadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Metadata",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "findUniqueMetadata",
            "type": {
              "kind": "OBJECT",
              "name": "Metadata",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "findUniqueMetadataOrThrow",
            "type": {
              "kind": "OBJECT",
              "name": "Metadata",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "getAccess",
            "type": {
              "kind": "OBJECT",
              "name": "Access",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "getLecture",
            "type": {
              "kind": "OBJECT",
              "name": "Lecture",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "getUser",
            "type": {
              "kind": "OBJECT",
              "name": "User",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "getVimeoAuthorizationUrl",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": [
              {
                "name": "state",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "groupByAccess",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "AccessGroupBy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "by",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              },
              {
                "name": "having",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "groupByLecture",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "LectureGroupBy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "by",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              },
              {
                "name": "having",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "groupByMetadata",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "MetadataGroupBy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "by",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              },
              {
                "name": "having",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "groupByUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "UserGroupBy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "by",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              },
              {
                "name": "having",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "lecture",
            "type": {
              "kind": "OBJECT",
              "name": "Lecture",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "lectures",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Lecture",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "user",
            "type": {
              "kind": "OBJECT",
              "name": "User",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "users",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "User",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "User",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "UserCount",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "accessRights",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Access",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "cursor",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "skip",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "take",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "email",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "UserCount",
        "fields": [
          {
            "name": "accessRights",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "UserCountAggregate",
        "fields": [
          {
            "name": "_all",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "email",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "UserGroupBy",
        "fields": [
          {
            "name": "_count",
            "type": {
              "kind": "OBJECT",
              "name": "UserCountAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_max",
            "type": {
              "kind": "OBJECT",
              "name": "UserMaxAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "_min",
            "type": {
              "kind": "OBJECT",
              "name": "UserMinAggregate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "email",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "UserMaxAggregate",
        "fields": [
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "UserMinAggregate",
        "fields": [
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "SCALAR",
        "name": "Any"
      }
    ],
    "directives": []
  }
} as unknown as IntrospectionQuery;

export const FetchLecturesDocument = gql`
    query FetchLectures($dateFilter: DateTimeFilter, $metadataFilter: MetadataListRelationFilter, $skip: Int, $take: Int, $published: Boolean) {
  lectures(
    where: {date: $dateFilter, metadata: $metadataFilter, published: {equals: $published}}
    skip: $skip
    take: $take
  ) {
    id
    title
    summary
    speakers
    speakersBio
    language
    room
    date
    addedAt
    published
    linkToVideoFile
    linkToVideoThumbnail
    metadata {
      id
      key
      value
      lectureId
    }
  }
}
    `;

export function useFetchLecturesQuery(options?: Omit<Urql.UseQueryArgs<FetchLecturesQueryVariables>, 'query'>) {
  return Urql.useQuery<FetchLecturesQuery, FetchLecturesQueryVariables>({ query: FetchLecturesDocument, ...options });
};
export const FetchLectureDocument = gql`
    query FetchLecture($where: LectureWhereUniqueInput!) {
  lecture(where: $where) {
    id
    title
    summary
    speakers
    speakersBio
    language
    room
    date
    addedAt
    published
    linkToVideoFile
    linkToVideoThumbnail
    metadata {
      id
      key
      value
      lectureId
    }
  }
}
    `;

export function useFetchLectureQuery(options: Omit<Urql.UseQueryArgs<FetchLectureQueryVariables>, 'query'>) {
  return Urql.useQuery<FetchLectureQuery, FetchLectureQueryVariables>({ query: FetchLectureDocument, ...options });
};
export const CreateLectureDocument = gql`
    mutation CreateLecture($data: LectureCreateInput!) {
  createOneLecture(data: $data) {
    id
    title
    summary
    speakers
    speakersBio
    language
    room
    date
    addedAt
    published
    linkToVideoFile
    linkToVideoThumbnail
  }
}
    `;

export function useCreateLectureMutation() {
  return Urql.useMutation<CreateLectureMutation, CreateLectureMutationVariables>(CreateLectureDocument);
};
export const UpdateLectureDocument = gql`
    mutation UpdateLecture($data: LectureUpdateInput!, $where: LectureWhereUniqueInput!) {
  updateOneLecture(data: $data, where: $where) {
    id
    title
    summary
    speakers
    speakersBio
    language
    room
    date
    addedAt
    published
    linkToVideoFile
    linkToVideoThumbnail
  }
}
    `;

export function useUpdateLectureMutation() {
  return Urql.useMutation<UpdateLectureMutation, UpdateLectureMutationVariables>(UpdateLectureDocument);
};
export const FetchUserDocument = gql`
    query FetchUser($where: UserWhereUniqueInput!) {
  user(where: $where) {
    id
    email
    firstName
    lastName
    accessRights {
      id
      startDate
      endDate
      isAdmin
      userId
    }
  }
}
    `;

export function useFetchUserQuery(options: Omit<Urql.UseQueryArgs<FetchUserQueryVariables>, 'query'>) {
  return Urql.useQuery<FetchUserQuery, FetchUserQueryVariables>({ query: FetchUserDocument, ...options });
};
export const FetchVimeoTokenDocument = gql`
    query FetchVimeoToken($code: String!) {
  fetchVimeoToken(code: $code)
}
    `;

export function useFetchVimeoTokenQuery(options: Omit<Urql.UseQueryArgs<FetchVimeoTokenQueryVariables>, 'query'>) {
  return Urql.useQuery<FetchVimeoTokenQuery, FetchVimeoTokenQueryVariables>({ query: FetchVimeoTokenDocument, ...options });
};
export const GetVimeoAuthorizationUrlDocument = gql`
    query GetVimeoAuthorizationUrl($state: String!) {
  getVimeoAuthorizationUrl(state: $state)
}
    `;

export function useGetVimeoAuthorizationUrlQuery(options: Omit<Urql.UseQueryArgs<GetVimeoAuthorizationUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetVimeoAuthorizationUrlQuery, GetVimeoAuthorizationUrlQueryVariables>({ query: GetVimeoAuthorizationUrlDocument, ...options });
};