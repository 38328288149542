import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { createRouteWithParams } from "../helpers/urlHelpers";
import { ROUTES } from "../routes";
import YearNavLink from "./YearNavLink";

export default function ArchiveFooter() {
  const navigate = useNavigate();
  return (
    <footer>
      <div className="footer-container">
        <div className="content-container">
          <div className="footer-container-content">
            <div className="footer-logo-and-info">
              <img
                onClick={() => navigate(ROUTES.HOME)}
                className="clickable"
                src="/gds-logo.svg"
                alt="GDS Prague Logo"
                width="106px"
                height="86px"
              />
              <p>
                GDS Prague. Stay In Touch,
                <br /> More Information Every Day...
              </p>
              <div className="footer-social">
                <a href="#">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="#">
                  <i className="fab fa-discord"></i>
                </a>
              </div>
              <p className="footer-conference">
                Conference Is Organized By Eventia S.R.O.,{" "}
                <a href="#">Terms & Conditions (CZ)</a>
              </p>
            </div>
            <div className="footer-contacts">
              <h4>Contacts</h4>
              <p>
                <a href="mailto:partners@gdsession.com">
                  partners@gdsession.com
                </a>
              </p>
              <p>
                <a href="#">Volunteers More Info</a>
              </p>
            </div>
            <div className="footer-pages">
              <h4>Pages</h4>
              <p>
                <YearNavLink to={2023} />
                <YearNavLink to={2022} />
                <YearNavLink to={2021} />
              </p>
              <p>
                <YearNavLink to={2020} />
                <YearNavLink to={2019} />
                <YearNavLink to={2018} />
              </p>
            </div>
          </div>

          <div className="footer-container-disclaimer">
            GDS Prague takes place under the auspices of the Deputy Mayor of
            Prague 4, Mr. Filip Vácha.
            <br />
            Conference is organized by Eventia s.r.o. and Active Radio a.s. The
            official ticket seller is Eventia s.r.o.{" "}
            <a href="#">Terms & Conditions (CZ)</a>
          </div>
        </div>
      </div>

      <div>
        <div className="content-container">
          <div className="footer-logos">
            <img src="/fin_eu.png" alt="EU Logo" />
            <img src="/npo.png" alt="Narodni Logo" />
            <img src="/min_kultury.png" alt="Ministry Logo" />
            <span>Financováno Evropskou unií – NextGeneration EU</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
