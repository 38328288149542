import React, { useState } from "react";
import { LectureCategory } from "../helpers/lectureCategory";
import { getClassNameForCategory } from "../helpers/categoryClassname";

type Props = {
  selectedCategories: LectureCategory[];
  onChange: (selected: LectureCategory[]) => void;
};

export default function CategoryFilter({
  selectedCategories,
  onChange,
}: Props) {
  const handleToggleCategory = (category: LectureCategory) => {
    
    console.log(selectedCategories)

    if (selectedCategories.includes(category)) {
      onChange(selectedCategories.filter((c) => c !== category));
    } else {
      onChange([...selectedCategories, category]);
    }
  };

  return (
    <div className="category-filter-container">
      <div>Filter by:</div>
      {Object.entries(LectureCategory).map(([key, value]) => (
        <div
          key={key}
          className={`category-filter-button ${
            selectedCategories.length === 0 ? "" : "underlined"
          } ${(selectedCategories.length === 0 || selectedCategories.includes(value)) ? "" : "disabled"}`}
          onClick={() => handleToggleCategory(value)}
        >
          <div
            className={`category-filter-icon ${getClassNameForCategory(value)}`}
          ></div>
          <div>{value}</div>
        </div>
      ))}
    </div>
  );
}
