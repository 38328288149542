import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "urql";
import { auth } from "../firebase";
import PasswordReset from "../components/PasswordReset"

export default function CompleteSignUp() {

  return (
    <div className="login-container">
      <h1>Welcome to GDS Archive</h1>
      <p>Complete sign up by creating a password for your account</p>
      <PasswordReset confirmButtonText="Complete sign up"></PasswordReset>
    </div>
  );
}
