import React, { MutableRefObject, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { FirebaseResponseCode, loginWithEmail } from "../firebase";
import { useNavigate } from "react-router-dom";
import LoginButton from "../components/LoginButton";
import { ROUTES } from "../routes";

const Login = () => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { login } = useAuth();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    setError("");
    setLoading(true);

    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (email && password) {
      var response = await login(email, password);

      if (response.code === FirebaseResponseCode.OK) {
        navigate(ROUTES.HOME);
      } else {
        setError(response.message);
      }
    }

    setLoading(false);
  }

  return (
    <div className="login-container">
      <h2>Login to GDS Archive</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            ref={emailRef}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            ref={passwordRef}
            required
          />
        </div>
        <LoginButton onClick={() => {}}></LoginButton>
      </form>
    </div>
  );
};

export default Login;
