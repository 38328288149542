import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FirebaseResponseCode,
  confirmNewPassword,
  resentPasswordResetLink,
  validatePasswordResetLink,
} from "../firebase";
import { ROUTES } from "../routes";

type Props = {
  confirmButtonText: string;
};

export default function PasswordReset({ confirmButtonText }: Props) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailPrompt, setEmailPrompt] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handlePasswordReset = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const queryParams = new URLSearchParams(location.search);
    const oobCode = queryParams.get("oobCode");

    if (!oobCode) {
      setMessage("Invalid or missing password reset code.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    var response = await confirmNewPassword(oobCode, newPassword);
    setMessage(response.message);

    if (response.code === FirebaseResponseCode.INVALID_LINK) {
      setEmailPrompt(true);
    }

    if (response.code !== FirebaseResponseCode.OK) {
      return;
    }

    navigate(ROUTES.LOGIN);
  };

  const handleResendLink = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var response = await resentPasswordResetLink(email);
    setMessage(response.message);
  };

  useEffect(() => {
    const validateLink = async () => {
      const queryParams = new URLSearchParams(location.search);
      const oobCode = queryParams.get("oobCode");

      if (!oobCode) {
        setMessage("Invalid link. Request a new one.");
        setEmailPrompt(true);
        return;
      }
      const response = await validatePasswordResetLink(oobCode);

      if (response.code === FirebaseResponseCode.OK) return;

      setEmailPrompt(true);
      setMessage(response.message);
    };

    validateLink();
  }, [location]);

  return (
    <div>
      {message && <p>{message}</p>}
      {!emailPrompt ? (
        <form  className="login-form" onSubmit={handlePasswordReset}>
          <div className="input-group">
            <label>New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Confirm New Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">{confirmButtonText}</button>
        </form>
      ) : (
        <form className="login-form" onSubmit={handleResendLink}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="button-text">Send New Link</button>
        </form>
      )}
    </div>
  );
}
