import { Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useGetVimeoAuthorizationUrlQuery } from "../../gql/generated-hooks";
import { useEffect, useState } from "react";


export const VimeoRoutes = () => {
  const { vimeoToken, setVimeoToken } = useAuth();
  const [state] = useState(Math.random().toString(36).substring(7)); // Generate state only once

  const [{ data, fetching, error }] = useGetVimeoAuthorizationUrlQuery({
    variables: { state: state },
    pause: !!vimeoToken, // Pause the query if the token is already set
  });

  useEffect(() => {
    const token = localStorage.getItem('vimeoAccessToken');
    if (token) {
      setVimeoToken(token);
    }
  }, []);

  useEffect(() => {
    if (!vimeoToken && data && data.getVimeoAuthorizationUrl) {
      window.location.href = data.getVimeoAuthorizationUrl;
    }
  }, [vimeoToken, data]);

  if (fetching) {
    return <p>Loading...</p>;
  }

  if (!vimeoToken) {
    return null; // Return null to prevent rendering anything during redirection
  }

  return <Outlet />;
};
