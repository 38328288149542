import { initializeApp } from "firebase/app";
import {
  ActionCodeSettings,
  User,
  confirmPasswordReset,
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  verifyPasswordResetCode,
} from "firebase/auth";
import { ROUTES } from "./routes";
import { combineRoutes } from "./helpers/urlHelpers";
import { getEnvVariable } from "./helpers/envHelpers";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);


export enum FirebaseResponseCode {
  OK,
  BAD_REQUEST,
  INTERNAL_ERROR,

  INVALID_LINK
}

export class FirebaseResponse {
  code: FirebaseResponseCode;
  message: string;

  constructor(
    code: FirebaseResponseCode,
    message: string,
  ) {
    this.code = code;
    this.message = message;
  }
}

export class FirebaseLoginResponse extends FirebaseResponse {
  user: User | null;

  constructor(
    code: FirebaseResponseCode,
    message: string,
    user: User | null = null
  ) {
    super(code, message)
    this.user = user;
  }
}


export const getCurrentUserToken = async (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          resolve(token);
        } catch (error) {
          console.error("Error fetching ID token:", error);
          reject(error);
        }
      } else {
        console.log("No user is currently signed in.");
        resolve(null);
      }
    });
  });
};

export async function loginWithEmail(
  email: string,
  password: string
): Promise<FirebaseLoginResponse> {
  try {
    let userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    return new FirebaseLoginResponse(
      FirebaseResponseCode.OK,
      "Sign in successful",
      userCredential.user
    );
  } catch (error) {
    return handleFirebaseLoginError(error);
  }
}

export async function finishSignUp(email: string): Promise<boolean> {
  if (isSignInWithEmailLink(auth, window.location.href) === false) {
    return false;
  }

  try {
    await signInWithEmailLink(auth, email, window.location.href);
  } catch (error) {
    console.error(error);
    return false;
  }

  return true;
}

export async function confirmNewPassword(oobCode: string, newPassword: string): Promise<FirebaseResponse> {
  try {
    await confirmPasswordReset(auth, oobCode, newPassword);
  } catch (error: any) {
    if (error.code === 'auth/expired-action-code') {
      return new FirebaseResponse(FirebaseResponseCode.INVALID_LINK, "The link has expired. Please request a new link.");
    } else if (error.code === 'auth/invalid-action-code') {
      return new FirebaseResponse(FirebaseResponseCode.INVALID_LINK, "The link is invalid. Please request a new link.");
    }
    else {
      return new FirebaseResponse(FirebaseResponseCode.INTERNAL_ERROR, `Error resetting password: ${error.message}`);
    } 
  }

  return new FirebaseResponse(FirebaseResponseCode.OK, "New password confirmed");
}

export async function validatePasswordResetLink(oobCode: string): Promise<FirebaseResponse> {
  try {

    await verifyPasswordResetCode(auth, oobCode);
  } catch (error: any) {
    if (error.code === 'auth/expired-action-code') {
      return new FirebaseResponse(FirebaseResponseCode.INVALID_LINK, "The link has expired. Please request a new link.");
    } else if (error.code === 'auth/invalid-action-code') {
      return new FirebaseResponse(FirebaseResponseCode.INVALID_LINK, "The link is invalid. Please request a new link.");
    }
    else {
      return new FirebaseResponse(FirebaseResponseCode.INTERNAL_ERROR, `Error validating the link: ${error.message}`);
    } 
  }

  return new FirebaseResponse(FirebaseResponseCode.OK, "Link is valid");
}

export async function resentPasswordResetLink(email: string): Promise<FirebaseResponse> {
  try {

    var domain = getEnvVariable("REACT_APP_DOMAIN")

    const actionCodeSettings: ActionCodeSettings = {
      url: combineRoutes(domain, ROUTES.COMPLETE_SIGN_UP),
      handleCodeInApp: true,
    };

    await sendPasswordResetEmail(auth, email, actionCodeSettings);
    return new FirebaseResponse(FirebaseResponseCode.OK, "New link sent successfully");
  }
  catch (error: any) {
      return new FirebaseResponse(FirebaseResponseCode.INTERNAL_ERROR, `Error sending new passsword link: ${error.message}`);
  }
  
}

function handleFirebaseLoginError(error: any): FirebaseLoginResponse {
  console.error(error);

  switch (error.code) {
    case "auth/user-disabled":
      return new FirebaseLoginResponse(
        FirebaseResponseCode.BAD_REQUEST,
        "That account has been disabled."
      );
    case "auth/user-not-found":
    case "auth/wrong-password":
      return new FirebaseLoginResponse(
        FirebaseResponseCode.BAD_REQUEST,
        "The email or password is incorrect."
      );
    case "auth/invalid-email":
      return new FirebaseLoginResponse(
        FirebaseResponseCode.BAD_REQUEST,
        "That email isn't valid. Please try a valid email address."
      );
    case "auth/invalid-login-credentials":
      return new FirebaseLoginResponse(
        FirebaseResponseCode.BAD_REQUEST,
        "Invalid credentials."
      );
    case "auth/missing-password":
      return new FirebaseLoginResponse(
        FirebaseResponseCode.BAD_REQUEST,
        "Missing password."
      );
    default:
      return new FirebaseLoginResponse(
        FirebaseResponseCode.INTERNAL_ERROR,
        "Something went wrong. Please try logging in again."
      );
  }
}
