import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { LectureCategory } from "../helpers/lectureCategory";
import {
  DateTimeFilter,
  Lecture,
  MetadataListRelationFilter,
  useFetchLecturesQuery,
  useUpdateLectureMutation,
} from "../gql/generated-hooks";
import LecturePreview from "../components/LecturePreview";
import CategoryFilter from "../components/CategoryFilter";

export default function LectureList() {
  const { currentUser } = useAuth();
  const { year } = useParams();
  const location = useLocation();
  const [dateFilter, setDateFilter] = useState<DateTimeFilter | undefined>(year
    ? { gte: `${year}-01-01T00:00:00Z`, lte: `${year}-12-31T23:59:59Z` }
    : undefined)
  const published = currentUser?.isAdmin() ? undefined : true;
  const take = 10;

  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [lectures, setLectures] = useState<Lecture[]>([]);

  const [categoriesFilter, setCategoriesFilter] = useState<
    MetadataListRelationFilter | undefined
  >(undefined);
  const [categoryFilterValues, setCategoryFilterValues] = useState<
    LectureCategory[]
  >([]);

  const [{ data, fetching, error }, reexecuteQuery] = useFetchLecturesQuery({
    variables: {
      dateFilter: dateFilter,
      metadataFilter: categoriesFilter,
      skip,
      take,
      published,
    },
    requestPolicy: 'network-only', 
  });

  useEffect(() => {
    // Resets the lectures and skip when the year changes
    setDateFilter(year ? { gte: `${year}-01-01T00:00:00Z`, lte: `${year}-12-31T23:59:59Z` } : undefined)
    setLectures([]);
    setSkip(0);
  }, [year]); 

  useEffect(() => {
    if (data && data.lectures) {
      setLectures((prev) => [...prev, ...data.lectures]);
      setHasMore(data.lectures.length === take);
    }
    
  }, [data]);

  useEffect(() => {
    setLectures([]);
    setSkip(0);
  }, [published]); 

  useEffect(() => {
    setCategoriesFilter(
      categoryFilterValues.length > 0
        ? {
            some: {
              key: { equals: "Category" },
              value: { in: categoryFilterValues.map((c) => c.toString()) },
            },
          }
        : undefined
    );
    // Reset pagination and lectures when filters change
    setSkip(0);
    setLectures([]);
  }, [categoryFilterValues]);

  const handleSeeMore = () => {
    const newSkip = skip + take;
    setSkip(newSkip);
  };

  if (fetching && lectures.length === 0) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="lecture-list-view content-container">
      <CategoryFilter
        selectedCategories={categoryFilterValues}
        onChange={setCategoryFilterValues}
      />
      <div className="lecture-list">
        {lectures.length > 0
          ? lectures.map((lecture) => (
              <LecturePreview lectureInfo={lecture} onPublish={() => setLectures(l => l.filter(item => item.id !== lecture.id))} key={lecture.id} />
            ))
          : !fetching && <p>No lectures available.</p>}
      </div>
      {fetching ? (
        <p>Loading more lectures...</p>
      ) : (
        hasMore && (
          <button
            className="button-text icon-button color-2"
            onClick={handleSeeMore}
          >
            See more
          </button>
        )
      )}
    </div>
  );
}
