import "./App.css";
import { Routes, Route } from "react-router-dom";
import ArchiveHeader from "./components/ArchiveHeader";
import Login from "./pages/Login";
import Lecture from "./pages/Lecture";
import LectureList from "./pages/LectureList";
import Upload from "./pages/Upload";
import { PrivateRoutes } from "./components/RouteManagers/PrivateRoutes";
import { AdminRoutes } from "./components/RouteManagers/AdminRoutes";
import { AuthProvider } from "./contexts/AuthContext";
import ArchiveFooter from "./components/ArchiveFooter";
import CompleteSignUp from "./pages/CompleteSignUp";
import { ROUTES } from "./routes";
import { VimeoRoutes } from "./components/RouteManagers/VimeoRoutes";
import VimeoAuthCallback from "./pages/VimeoAuthCallback";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <ArchiveHeader />
        <Routes>
          <Route path={ROUTES.HOME} element={<LectureList />} />
          <Route path={ROUTES.YEAR} element={<LectureList />} />
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.COMPLETE_SIGN_UP} element={<CompleteSignUp />} />

          <Route element={<PrivateRoutes />}>
            <Route path={ROUTES.LECTURE} element={<Lecture />} />
          </Route>

          <Route element={<AdminRoutes />}>
            <Route element={<VimeoRoutes />}>
              <Route path={ROUTES.UPLOAD} element={<Upload />} />
            </Route>

            <Route path={ROUTES.AUTH_VIMEO_CALLBACK} element={<VimeoAuthCallback />} />
          </Route>
        </Routes>
        <ArchiveFooter />
      </AuthProvider>
    </div>
  );
}

export default App;
