import { LectureCategory } from "./lectureCategory";

export function getClassNameForCategory(value: LectureCategory): string {
  switch (value) {
    case LectureCategory.ARTAUDIO:
      return "art-audio";
    case LectureCategory.TECHNICAL:
      return "technical";
    case LectureCategory.BUSINESS:
      return "business";
    case LectureCategory.DESIGN:
      return "design";
    case LectureCategory.INDUSTRYSUPPORT:
      return "industry-support";
    default:
      return "other";
  }
}
