import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  useFetchLectureQuery,
  useUpdateLectureMutation,
} from "../gql/generated-hooks";
import { useAuth } from "../contexts/AuthContext";
import Vimeo from "@u-wave/react-vimeo";
import VideoPlayer from "../components/VideoPlayer";
import { createRouteWithParams } from "../helpers/urlHelpers";
import { ROUTES } from "../routes";
import YearNavLink from "../components/YearNavLink";
import Categories from "../components/Categories";

export default function Lecture() {
  const { currentUser } = useAuth();
  const { id } = useParams();

  const [result] = useFetchLectureQuery({ variables: { where: { id: id } } });
  const [updateLectureResult, executeUpdateLectureResult] =
    useUpdateLectureMutation();

  const { data, fetching, error } = result;

  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [speakers, setSpeakers] = useState("");
  const [speakersBio, setSpeakersBio] = useState("");
  const [year, setYear] = useState<number>(0);

  // Handle the edit button click
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Handle the save button click
  const handleSaveClick = async () => {
    // Add logic to save the updated fields
    setIsEditing(false);

    try {
      const updateLecture = await executeUpdateLectureResult({
        data: {
          title: { set: title },
          summary: { set: summary },
          speakers: { set: speakers },
        },
        where: { id: id },
      });

      console.log(updateLecture); // Check the result
      alert("Lecture updated succefully"); // Show success message
    } catch (error) {
      console.error("Error updating the lecture:", error);
      alert("Error updating the lecture. Please try again."); // Show error message
    }
  };

  useEffect(() => {
    setTitle(data?.lecture?.title || "");
    setSummary(data?.lecture?.summary || "");
    setSpeakers(data?.lecture?.speakers || "");
    setSpeakersBio(data?.lecture?.speakersBio || "");

    if (data?.lecture?.date) {
      const lectureDate = new Date(data.lecture.date);
      setYear(lectureDate.getFullYear());
    }
  }, [data]);

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Error {error.message}</p>;

  return (
    <div className="lecture-container">
      <nav className="breadcrumb">
        <NavLink to={ROUTES.HOME}>Home</NavLink> &gt; <YearNavLink to={year} />{" "}
        &gt;{" "}
        <NavLink
          to={createRouteWithParams(ROUTES.LECTURE, {
            id: data?.lecture?.id ?? "",
          })}
        >
          {title}
        </NavLink>
      </nav>
      <div>
        <h1 className="lecture-title">
          {currentUser?.isAdmin() && isEditing ? (
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          ) : (
            title
          )}
        </h1>
        {currentUser?.isAdmin() && (
          <button onClick={isEditing ? handleSaveClick : handleEditClick}>
            {isEditing ? "Save" : "Edit"}
          </button>
        )}
      </div>

      <VideoPlayer videoLink={data?.lecture?.linkToVideoFile!}></VideoPlayer>
      <div className="lecture-details">
        <div className="lecture-about">
          <h2>About lecture</h2>
          <p>
            {currentUser?.isAdmin() && isEditing ? (
              <textarea
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
            ) : (
              summary
            )}
          </p>
        </div>
        <div className="lecture-speaker">
          <h2>About {speakers}</h2>
          <p>
            {currentUser?.isAdmin() && isEditing ? (
              <textarea
                value={speakers}
                onChange={(e) => setSpeakers(e.target.value)}
              />
            ) : (
              speakersBio
            )}
          </p>
        </div>
      </div>
      <Categories metadata={data?.lecture?.metadata} />
    </div>
  );
}
