/**
 * Retrieves the value of the specified environment variable.
 * 
 * This function checks if the environment variable is set and returns its value.
 * If the environment variable is not set, it throws an error.
 * 
 * @param {string} key - The name of the environment variable to retrieve.
 * 
 * @returns {string} - The value of the specified environment variable.
 * 
 * @throws {Error} - Throws an error if the environment variable is not set.
 * 
 * @example
 * ```
 * const apiUrl = getEnvVariable('REACT_APP_API_URL');
 * console.log(apiUrl); // Outputs the value of REACT_APP_API_URL
 * ```
 */
export const getEnvVariable = (key: string): string => {
    const value = process.env[key];
    if (!value) {
      throw new Error(`Environment variable ${key} is not set.`);
    }
    return value;
  };