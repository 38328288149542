import React from "react";
import { Lecture, useUpdateLectureMutation } from "../gql/generated-hooks";
import { useNavigate } from "react-router-dom";
import { extractUniqueCategories } from "../helpers/extractCategories";
import { createRouteWithParams } from "../helpers/urlHelpers";
import { ROUTES } from "../routes";
import { useAuth } from "../contexts/AuthContext";
import { getClassNameForCategory } from "../helpers/categoryClassname";
import Categories from "./Categories";

type Props = {
  lectureInfo: Lecture;
  onPublish: () => void;
};

export default function LecturePreview({ lectureInfo, onPublish }: Props) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [updateLectureResult, executeUpdateLectureResult] =
    useUpdateLectureMutation();

  function navigateToLecture(id: string) {
    navigate(createRouteWithParams(ROUTES.LECTURE, { id: id }));
  }

  async function publishLecture(id: string) {
    try {
      const updateLecture = await executeUpdateLectureResult({
        data: {
          published: { set: true },
        },
        where: { id: id },
      });

      onPublish();
      console.log(updateLecture); // Check the result
      alert("Lecture published succefully"); // Show success message
    } catch (error) {
      console.error("Error publishing the lecture:", error);
      alert("Error publishing the lecture."); // Show error message
    }
  }

  return (
    <div
      className="lecture-preview"
      style={{
        backgroundColor: lectureInfo.published ? "" : "#ffdbd9",
      }}
    >
      <div className="lecture-preview-data">
        <div className="lecture-preview-thumbnail">
          <img
            className="thumbnail-image"
            src={lectureInfo.linkToVideoThumbnail}
            alt={lectureInfo.title}
            onClick={() => navigateToLecture(lectureInfo.id)}
            width={200}
          />
        </div>

        <div className="lecture-preview-info">
          <div
            onClick={() => navigateToLecture(lectureInfo.id)}
            style={{ fontWeight: "800" }}
          >
            {lectureInfo.title}
          </div>

          <p>{lectureInfo.speakers}</p>
          <Categories metadata={lectureInfo.metadata} />
        </div>
      </div>
      <div>
        {currentUser?.isAdmin() && !lectureInfo.published && (
          <button
            onClick={() => publishLecture(lectureInfo.id)}
            className="lecture-preview-button"
          >
            Publish
          </button>
        )}
        <button
          onClick={() => navigateToLecture(lectureInfo.id)}
          className="lecture-preview-button background-color-1 color-2"
        >
          Show lecture
        </button>
      </div>
    </div>
  );
}
