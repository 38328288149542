import React from "react";
import Vimeo from "@u-wave/react-vimeo"; // Ensure you have installed this package

type Props = {
  videoLink: string;
};

export default function VideoPlayer({ videoLink }: Props) {
  const isVimeoLink = (link: string) => {
    // Check if the link contains "vimeo"
    return link.includes("vimeo");
  };

  return (
    <>
      {isVimeoLink(videoLink) ? (
        <Vimeo video={videoLink} width="800" height="500"/>
      ) : (
        <video controls width="500">
          <source src={videoLink} type="video/mp4" />
        </video>
      )}
    </>
  );
}
