import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import LoginButton from "./LoginButton";
import { ROUTES } from "../routes";
import { createRouteWithParams } from "../helpers/urlHelpers";
import YearNavLink from "./YearNavLink";
import DropdownMenu from "./DropdownMenu";
import { MdLogout } from "react-icons/md";

export default function ArchiveHeader() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = currentUser?.isAdmin();

  // Extract the year from the pathname
  const match = location.pathname.match(/^\/(20\d{2})\/?.*$/);
  const year = match ? match[1] : null;

  return (
    <header className="page-header">
      <div className="page-header-top-section">
        <img className="logo clickable" onClick={() => navigate(ROUTES.HOME)} src="/gds-logo.svg" alt="Logo" />

        <nav className="links button-text">
          <YearNavLink to={2023} />
          <YearNavLink to={2022} />
          <YearNavLink to={2021} />
          <DropdownMenu
            toggleText="Older"
            dropdownElements={[
              <YearNavLink to={2020} />,
              <YearNavLink to={2019} />,
              <YearNavLink to={2018} />,
            ]}
          />
        </nav>

        {currentUser ? (
          <div className="button-text account-name-logout">
            {isAdmin && <button className="button-text" onClick={() => navigate(ROUTES.UPLOAD)}>Upload lecture</button>}
            {isAdmin && "Admin: "}
            {currentUser.email}{" "}
            <button className="icon-button button-text" onClick={logout}>
              {<MdLogout />}
            </button>
          </div>
        ) : (
          <LoginButton onClick={() => navigate(ROUTES.LOGIN)} />
        )}
      </div>
      <div className="page-header-title">
        <h1 className="header-title-text">
          Video Archive GDS Prague {year}
        </h1>
      </div>
    </header>
  );
}
