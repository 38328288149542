import { Metadata } from "../gql/generated-hooks";
import { LectureCategory } from "./lectureCategory";

export const extractUniqueCategories = (metadata: Metadata[] | undefined): LectureCategory[] => {
  if (metadata == undefined)
    return [];

  const categories = metadata.reduce((acc: LectureCategory[], meta: Metadata) => {
    if (meta.key === "Category") {
      const category: LectureCategory = Object.values(LectureCategory).includes(meta.value as LectureCategory)
        ? meta.value as LectureCategory
        : LectureCategory.OTHER;  // Default to OTHER if no match is found

      if (!acc.includes(category)) {
        acc.push(category);
      }
    }
    return acc;
  }, []);
  return categories;
};
