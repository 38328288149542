import axios from "axios";

interface UploadResult {
  success: boolean;
  videoLink?: string;
  thumbnailLink?: string;
  error?: string;
}

async function uploadVideoToVimeo(
  file: File,
  title: string,
  year: string,
  description: string,
  accessToken: string,
  setProgress: (progress: number) => void
): Promise<UploadResult> {
  // Step 1: Create an upload ticket
  const createVideo = async (size: number) => {
    try {
      var folderId = await getOrCreateFolderByYear(accessToken, year);
      const response = await axios.post(
        "https://api.vimeo.com/me/videos",
        {
          upload: {
            approach: "tus",
            size: size,
          },
          name: title,
          embed: {
            buttons: {
              like: false,
              share: false,
              watchlater: false,
            },
            end_screen_type: "thumbnail",
            logos: {
              vimeo: false,
            },
            title: {
              name: "hide",
              owner: "hide",
              portrait: "hide",
            },
          },
          description: description,
          folder_uri: `/folders/${folderId}`,
          embed_domains: "gdsession.com",
          privacy: {
            embed: "whitelist",
            view: "disable",
          },
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/vnd.vimeo.*+json;version=3.4",
          },
        }
      );

      if (response.data.upload.approach === "tus") {
        return {
          uploadLink: response.data.upload.upload_link,
          videoUri: response.data.uri,
        };
      } else {
        throw new Error("Failed to create video with tus approach");
      }
    } catch (error) {
      throw new Error(`Error creating video: ${(error as Error).message}`);
    }
  };

  // Step 2: Upload the video
  const uploadChunks = async (
    file: File,
    uploadLink: string,
    setProgress: (progress: number) => void
  ) => {
    const chunkSize = 5242880; // 5MB chunk size
    const totalSize = file.size;
    let offset = 0;

    try {
      while (offset < totalSize) {
        const chunk = file.slice(offset, offset + chunkSize);
        const chunkBuffer = await chunk.arrayBuffer();

        const response = await axios.patch(uploadLink, chunkBuffer, {
          headers: {
            "Tus-Resumable": "1.0.0",
            "Upload-Offset": offset,
            "Content-Type": "application/offset+octet-stream",
          },
        });

        offset = parseInt(response.headers["upload-offset"], 10);
        setProgress((offset / totalSize) * 100);

        if (offset >= totalSize) {
          return true;
        }
      }
    } catch (error) {
      throw new Error(`Error uploading video: ${(error as Error).message}`);
    }

    return false;
  };

  // Step 3: Get thumbnail
    const getThumbnailLink = async (videoId: string) : Promise<string> => {
      const maxRetries = 10;
      const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        try {
          const response = await axios.get(`https://api.vimeo.com/videos/${videoId}/pictures`, {
            headers: {
              Authorization: `bearer ${accessToken}`,
              Accept: 'application/vnd.vimeo.*+json;version=3.4',
            },
          });

          //const picturesUri = response.data.metadata.connections.pictures.uri;
          console.log(response);
          // const picturesResponse = await axios.get(`https://api.vimeo.com${picturesUri}`, {
          //   headers: {
          //     Authorization: `bearer ${accessToken}`,
          //     Accept: 'application/vnd.vimeo.*+json;version=3.4',
          //   },
          // });
          // console.log(picturesResponse);
          if (response.data.data[0].base_link) {
            return response.data.data[0].base_link;
          } else {
            throw new Error('No thumbnail found');
          }
        } catch (error) {
          if (attempt < maxRetries) {
            await delay(10000); // Wait for 10 seconds before retrying
          } else {
            throw new Error(`Error fetching thumbnail after ${maxRetries} attempts: ${(error as Error).message}`);
          }
        }
      }

      return ""
    };

  try {
    const { uploadLink, videoUri } = await createVideo(file.size);
    const uploadSuccess = await uploadChunks(file, uploadLink, setProgress);

    if (uploadSuccess) {
      const videoId = videoUri.split("/").pop();
      console.log(videoUri);
      const videoLink = `https://vimeo.com/${videoId}`;
      const thumbnailLink = await getThumbnailLink(videoId);
      //const thumbnailLink = "";
      return { success: true, videoLink, thumbnailLink };
    } else {
      return { success: false, error: "Upload failed" };
    }
  } catch (error) {
    return { success: false, error: (error as Error).message };
  }
}

async function getOrCreateFolderByYear(
  accessToken: string,
  year: string
): Promise<string> {
  const getAllFolders = async () => {
    try {
      const response = await axios.get(`https://api.vimeo.com/me/projects`, {
        headers: {
          Authorization: `bearer ${accessToken}`,
          Accept: "application/vnd.vimeo.*+json;version=3.4",
        },
      });

      return response.data.data;
    } catch (error) {
      throw new Error(`Error fetching folders: ${(error as Error).message}`);
    }
  };

  const createFolder = async (folderName: string) => {
    try {
      const response = await axios.post(
        `https://api.vimeo.com/me/projects`,
        {
          name: folderName,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/vnd.vimeo.*+json;version=3.4",
          },
        }
      );

      return response.data.uri.split("/").pop(); // Return the folder ID
    } catch (error) {
      throw new Error(`Error creating folder: ${(error as Error).message}`);
    }
  };

  // Fetch all folders
  const folders = await getAllFolders();

  // Check if the folder with the given year exists
  const existingFolder = folders.find((folder: any) => folder.name === year);

  if (existingFolder) {
    return existingFolder.uri.split("/").pop();
  } else {
    // Create a new folder
    const newFolderId = await createFolder(year);
    return newFolderId;
  }
}

export default uploadVideoToVimeo;
