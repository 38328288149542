import React from "react";
import { createRouteWithParams } from "../helpers/urlHelpers";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../routes";

type Props = {
  to: number;
};

export default function YearNavLink({ to }: Props) {
  return (
    <>
      <NavLink to={createRouteWithParams(ROUTES.YEAR, { year: to.toString() })}>
        {to}
      </NavLink>
    </>
  );
}
