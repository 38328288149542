import { Access } from "../gql/generated-hooks";

export class UserData {
  public id: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  private accessUntil: Date | null;
  private adminAccessUntil: Date | null;

  constructor(
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    accessRights: Access[]
  ) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.accessUntil = this.getLongestAccess(accessRights);
    this.adminAccessUntil = this.getAdminAccess(accessRights);
  }

  private getLongestAccess(accessRights: Access[]): Date | null {
    if (!accessRights || accessRights.length === 0) return null;
    return new Date(
      Math.max(
        ...accessRights.map((record) => new Date(record.endDate).getTime())
      )
    );
  }

  private getAdminAccess(accessRights: Access[]): Date | null {
    if (!accessRights) return null;

    const adminAccessRights = accessRights.filter((record) => record.isAdmin);
    return this.getLongestAccess(adminAccessRights);
  }

  public isAdmin(): boolean {
    if (this.adminAccessUntil) {
      return new Date() <= this.adminAccessUntil;
    }
    return false;
  }

  public hasAccess(): boolean {
    if (this.accessUntil) {
      return new Date() <= this.accessUntil;
    }
    return false;
  }
}
