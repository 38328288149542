export const combineRoutes = (...paths: string[]): string => {
  return (
    paths
      .map((part, i) => {
        if (i === 0) {
          // For the first segment, it trims any trailing slashes from the end of the string
          return part.trim().replace(/[/]*$/g, "");
        } else {
          // For subsequent segments, it trims slashes from both the beginning and the end using
          return part.trim().replace(/(^[/]*|[/]*$)/g, "");
        }
      })
      // Removes empty segments
      .filter((part) => part.length > 0)
      .join("/")
  );
};

export const createRouteWithParams = (template: string, params: { [key: string]: string }): string => {
  let path = template;
  for (const [key, value] of Object.entries(params)) {
    path = path.replace(`:${key}`, value);
  }
  return path;
};
