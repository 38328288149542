import React from "react";
import { MdLogin } from "react-icons/md";

type Props = {
  onClick: () => void;
};

export default function LoginButton({ onClick }: Props) {
  return (
    <div className="login-button-container">
      <button className="text-icon-button button-text" onClick={onClick}>
        <div>Login</div>{<MdLogin />}
      </button>
    </div>
  );
}
