import React, { useEffect, useState } from "react";
import { useQuery } from "urql";
import {
  FetchVimeoTokenDocument,
  FetchVimeoTokenQuery,
  FetchVimeoTokenQueryVariables,
  useFetchVimeoTokenQuery,
} from "../gql/generated-hooks";
import { ROUTES } from "../routes";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function VimeoAuthCallback() {
  const navigate = useNavigate();
  const { setVimeoToken } = useAuth();
  const [code, setCode] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);

  const [result, reexecuteQuery] = useFetchVimeoTokenQuery({
    variables: { code: code! },
    pause: !code,
  });

  const { data, fetching, error } = result;

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    const state = query.get("state");

    if (code && state) {
      setCode(code);
      setState(state);
    } else {
      console.error("Authorization code or state is missing or invalid");
      // Handle invalid state or missing code
    }
  }, []);

  useEffect(() => {
    if (code) {
      reexecuteQuery();
    }
  }, [code]);

  useEffect(() => {
    if (data && data.fetchVimeoToken) {
      const accessToken = data.fetchVimeoToken;
      // Redirect or handle successful authentication
      setVimeoToken(accessToken);
      localStorage.setItem('vimeoAccessToken', accessToken);
      navigate(ROUTES.UPLOAD);
    }
  }, [data]);

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return <div>Authorizing...</div>;
}
