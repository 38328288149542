import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Language,
  LectureCreateInput,
  useCreateLectureMutation,
} from "../gql/generated-hooks";
import uploadVideoToVimeo from "../helpers/vimeoUpload";
import { LectureCategory } from "../helpers/lectureCategory";
import { useAuth } from "../contexts/AuthContext";
import { NavLink } from "react-router-dom";
import { createRouteWithParams } from "../helpers/urlHelpers";
import { ROUTES } from "../routes";

const categoryOptions = Object.keys(LectureCategory).map((key) => ({
  value: LectureCategory[key as keyof typeof LectureCategory],
  label: LectureCategory[key as keyof typeof LectureCategory],
}));

export default function Upload() {
  const { vimeoToken } = useAuth();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [videoId, setVideoId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [date, setDate] = useState<Date | null>(new Date());
  const [language, setLanguage] = useState(Language.English);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [room, setRoom] = useState("");
  const [speakers, setSpeakers] = useState("");
  const [speakersBio, setSpeakersBio] = useState("");
  const [progress, setProgress] = useState<number>(0);
  const [categories, setCategories] = useState<string[]>([]);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCategoryChange = (selectedOptions: any) => {
    setCategories(selectedOptions.map((option: any) => option.value));
  };

  const [uploadResult, executeUploadVideo] = useCreateLectureMutation();

  function resetState() {
    setSelectedFile(null);
    setDate(new Date());
    setLanguage(Language.English);
    setTitle("");
    setSummary("");
    setRoom("");
    setSpeakers("");
    setSpeakersBio("");
    setCategories([]);
    setProgress(0);
    setError(null);
  }

  function createLectureMetadata(
    linkToVideo: string,
    thumbnailLink: string,
    publish: boolean
  ): LectureCreateInput {
    return {
      date: date ? date.toISOString() : new Date().toISOString(),
      language: Language.English,
      linkToVideoFile: linkToVideo,
      linkToVideoThumbnail: thumbnailLink,
      title: title,
      summary: summary,
      room: room,
      speakers: speakers,
      speakersBio: speakersBio,
      published: publish,
      metadata: {
        create: categories.map((category) => ({
          key: "Category",
          value: category,
        })),
      },
    };
  }

  async function handleMetadataUpload(
    linkToVideo: string,
    thumbnailLink: string,
    publish: boolean
  ) {
    if (!selectedFile) {
      alert("Please select a file");
      return;
    }

    const lectureData = createLectureMetadata(
      linkToVideo,
      thumbnailLink,
      publish
    );

    try {
      const uploadResult = await executeUploadVideo({ data: lectureData });

      if (uploadResult.error) {
        console.log(uploadResult);
        throw new Error(uploadResult.error.message);
      }

      alert("Video uploaded successfully!"); // Show success message
      setVideoId(uploadResult.data?.createOneLecture.id!);
      // Reset the form fields
      resetState();
    } catch (error) {
      console.error("Error uploading the video:", error);

      let errorMessage = "Error uploading the video. Please try again.";

      alert(errorMessage); // Show error message
    } finally {
      setLoading(false);
    }
  }

  const handleUpload = async (publish: boolean) => {
    if (!selectedFile) {
      alert("Please select a file");
      return;
    }

    setLoading(true); // Show processing message
    const result = await uploadVideoToVimeo(
      selectedFile,
      title,
      date?.getFullYear().toString() ?? "",
      summary,
      vimeoToken!,
      setProgress
    );

    if (result.success && result.videoLink && result.thumbnailLink) {
      setVideoId(null);
      handleMetadataUpload(result.videoLink, result.thumbnailLink, publish);
    } else {
      setError(result.error || "Unknown error");
      setLoading(false);
    }
  };

  return (
    <div className="upload-container">
      {loading ? (
        <div>
          {progress < 100 ? (
            <div>
              <p>Uploading...</p>
              <progress value={progress} max="100"></progress>
              <span>{Math.round(progress)}%</span>
            </div>
          ) : (
            <div>Processing..</div>
          )}
          {error && (
            <div>
              <p>Error: {error}</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          {/* {videoId && (
            <div>
              <p>
                Upload complete! View your video{" "}
                <NavLink
                  to={createRouteWithParams(ROUTES.LECTURE, {
                    id: videoId ?? "",
                  })}
                >
                  here
                </NavLink>
              </p>
            </div>
          )} */}

          <h2>Upload Video</h2>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="summary">Summary:</label>
            <textarea
              id="summary"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="speakers">Speakers' names:</label>
            <input
              type="text"
              id="speakers"
              value={speakers}
              onChange={(e) => setSpeakers(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="speakersBio">Speakers' Bio:</label>
            <textarea
              id="speakersBio"
              value={speakersBio}
              onChange={(e) => setSpeakersBio(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="room">Room:</label>
            <input
              type="text"
              id="room"
              value={room}
              onChange={(e) => setRoom(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="language">Language:</label>
            <select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value as Language)}
              required
            >
              {Object.keys(Language).map((key) => (
                <option
                  key={key}
                  value={Language[key as keyof typeof Language]}
                >
                  {key}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="date">Date and Time:</label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              showTimeSelect
              dateFormat="Pp"
            />
          </div>
          <div className="form-group">
            <label>Category:</label>
            <Select
              isMulti
              options={categoryOptions}
              onChange={handleCategoryChange}
              value={categories.map((category) => ({
                value: category,
                label: category,
              }))}
            />
          </div>
          <div className="form-group">
            <label htmlFor="file">Video file:</label>
            <input type="file" id="file" onChange={handleFileChange} required />
          </div>
          <div className="button-group">
            <button onClick={() => handleUpload(true)}>
              Upload and Publish
            </button>
            <button onClick={() => handleUpload(false)}>Upload</button>
          </div>
        </div>
      )}
    </div>
  );
}
