import React from "react";
import { extractUniqueCategories } from "../helpers/extractCategories";
import { Metadata } from "../gql/generated-hooks";
import { getClassNameForCategory } from "../helpers/categoryClassname";

type Props = {
  metadata: Metadata[] | undefined;
};

export default function Categories({ metadata }: Props) {
  const uniqueCategories = extractUniqueCategories(metadata);
  return (
    <div className="lecture-preview-info-categories">
      {uniqueCategories.map((category, index) => (
        <div key={index} className="category-filter-button">
          <div
            className={`category-filter-icon-small ${getClassNameForCategory(
              category
            )}`}
          ></div>
          <div>{category}</div>
        </div>
      ))}
    </div>
  );
}
