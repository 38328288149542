import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  Client,
  Provider,
  cacheExchange,
  fetchExchange,
  mapExchange,
} from "urql";
import { authExchange } from "@urql/exchange-auth";
import { BrowserRouter } from "react-router-dom";
import { auth, getCurrentUserToken } from "./firebase";

const client = new Client({
  url: `${process.env.REACT_APP_GRAPHQL_URL}`,
  exchanges: [
    cacheExchange,
    mapExchange({
      onError(error, _operation) {
        const isAuthError = error.graphQLErrors.some(
          (e) => e.extensions?.code === "FORBIDDEN"
        );
        if (isAuthError) {
          auth.signOut();
        }
      },
    }),
    authExchange(async (utils) => {
      let token: string | null = null;

      try {
        token = await getCurrentUserToken();
        console.log("Initial token:", token);
      } catch (error) {
        console.error("Error during initial token fetch:", error);
      }

      return {
        addAuthToOperation(operation) {
          if (!token) return operation;

          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        },
        didAuthError(error, _operation) {
          return error.graphQLErrors.some(
            (e) => e.extensions?.code === "FORBIDDEN"
          );
        },
        willAuthError(operation) {

          // Bit of a hacky solution 
          // The token was not being registred after login - page had to be refreshed first
          // This fixes the issue by forcing a call of refreshAuth when token is missing
          // FetchLectures is excluded because it is public and does not need token 
          if (
            !token &&
            operation.query.definitions.some(
              (def) =>
                def.kind === "OperationDefinition" &&
                def.name?.value !== "FetchLectures"
            )
          )
            return true;

          return false;
        },
        async refreshAuth() {
          try {
            token = await getCurrentUserToken();
            console.log("Refreshed token:", token);
          } catch (error) {
            console.error("Error during token refresh:", error);
          }
        },
      };
    }),
    fetchExchange,
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider value={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
