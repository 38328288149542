import React, { useContext, useState, useEffect, ReactNode } from "react";
import { auth, FirebaseLoginResponse, loginWithEmail } from "../firebase";
import {
  FetchLectureQuery,
  FetchUserDocument,
  User,
  useFetchUserQuery,
} from "../gql/generated-hooks";
import { DocumentNode } from "graphql";
import { useClient } from "urql";
import { User as FirebaseUser } from "firebase/auth";
import { UserData } from "./UserData";

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

interface AuthContextType {
  currentUser: UserData | null;

  vimeoToken: string | null;

  login: (email: string, password: string) => Promise<FirebaseLoginResponse>;
  logout: () => Promise<void>;

  setVimeoToken: (token: string) => void;
}

export function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<UserData | null>(null);
  const [vimeoToken, setVimeoTokenState] = useState<string | null>(null);
  const client = useClient();
  const [loadingUser, setLoadingUser] = useState(true);


  async function login(
    email: string,
    password: string
  ): Promise<FirebaseLoginResponse> {
    return await loginWithEmail(email, password);
  }

  async function logout() {
    setVimeoTokenState(null); // Clear state
    localStorage.removeItem('vimeoAccessToken'); // Clear local storage
    return await auth.signOut();
  }

  //   function resetPassword(email) {
  //     return auth.sendPasswordResetEmail(email)
  //   }

  //   function updateEmail(email) {
  //     return currentUser.updateEmail(email)
  //   }

  //   function updatePassword(password) {
  //     return currentUser.updatePassword(password)
  //   }

  async function fetchAndSetUserData(user: FirebaseUser | null) {
    if (!user) {
      setCurrentUser(null);
      return;
    }

    const result = await client
      .query(FetchUserDocument, { where: { id: user.uid } })
      .toPromise();

    if (result.error) {
      throw new Error(result.error.message);
    }

    if (result.data.user === null) {
      throw new Error("User not found in database");
    }

    var data = result.data.user as User;

    
    console.log(result)

    const newUser = new UserData(
      data.id,
      data.email,
      data.firstName,
      data.lastName,
      data.accessRights
    );

    setCurrentUser(newUser);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      await fetchAndSetUserData(user);

      setLoadingUser(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    vimeoToken,
    login,
    logout,
    setVimeoToken: setVimeoTokenState,
    // resetPassword,
    // updateEmail,
    // updatePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loadingUser && children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
